/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'camera': {
    width: 14,
    height: 11,
    viewBox: '0 0 14 11',
    data: '<path pid="0" d="M4.276.243A.632.632 0 014.773 0h4.454c.194 0 .376.09.497.243l1.082 1.375h1.603c.877 0 1.591.723 1.591 1.614v6.153c0 .892-.712 1.615-1.59 1.615H1.59C.711 11 0 10.277 0 9.385V3.232c0-.891.714-1.614 1.591-1.614h1.603L4.276.243zm.803 1.051L3.997 2.67a.632.632 0 01-.497.243H1.591a.32.32 0 00-.318.32v6.153c0 .177.142.32.316.32H12.41a.32.32 0 00.317-.32V3.232a.32.32 0 00-.318-.32H10.5a.632.632 0 01-.497-.243L8.921 1.294H5.08zM7 8.735c-1.582 0-2.864-1.303-2.864-2.911 0-1.609 1.282-2.912 2.864-2.912 1.582 0 2.864 1.303 2.864 2.912 0 1.608-1.282 2.911-2.864 2.911zm0-1.294c.879 0 1.59-.724 1.59-1.617 0-.894-.711-1.618-1.59-1.618-.879 0-1.59.724-1.59 1.618 0 .893.711 1.617 1.59 1.617z" _fill="#fff"/>'
  }
})
